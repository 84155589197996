import { ComponentType } from 'react';

import { NotFound } from '~components';
import { OAuthCallback, OAuthRedirect } from '~containers';
import {
  AuthorsPage,
  BadWordsPage,
  ChangeLogPage,
  CommentsPage,
  DepartmentsPage,
  EditorPage,
  EmployeesPage,
  FeedbackPage,
  FlowsPage,
  LoginPage,
  PublicationsPage,
  RolePage,
  RolesPage,
  SchedulePage,
  SettingsCategoriesSectionsPage,
  SettingsCommonPage,
  SettingsEmailsPage,
  SettingsExperimentsPage,
  SettingsFlagsPage,
  SettingsIndexPage,
  SettingsInfoPagesPage,
  SettingsLogoPage,
  SettingsMovePublicationsPage,
  SettingsNotificationPage,
  SettingsPlaylistsPage,
  SettingsPushPage,
  SettingsQueryOrderPage,
  SettingsRedirectsPage,
  SettingsSocialNetworksPage,
  SettingsSpecProjectsPage,
  SettingsSymbolsPage,
  SettingsTimersPage,
  SettingsViqeoPage,
  TagsPage,
  VideoPage,
  VideosPage,
} from '~pages';
import { RegionsPage } from '~pages/RegionsPage';

interface IRoute {
  authority?: string[] | string;
  component: ComponentType;
  defaultFilter?: string;
  hideFilters?: string[];
  icon?: string;
  inMenu?: boolean;
  path?: string;
  showPostsHeader?: boolean;
  submenu?: Record<string, IRoute>;
  title?: string;
}

const routes: { private: Record<string, IRoute>; public: Record<string, IRoute> } = {
  public: {
    OAuthRedirect: {
      path: '/connect/life/callback',
      component: OAuthRedirect,
    },
    OAuthCallback: {
      path: '/oauth/callback',
      component: OAuthCallback,
    },
    Login: {
      component: LoginPage,
      path: '/login',
    },
  },
  private: {
    Quiz: {
      component: EditorPage,
      path: '/quiz/:id',
      title: 'Редактирование опроса',
      authority: 'quiz.view',
    },
    Index: {
      component: PublicationsPage,
      path: '/publications',
      title: 'Публикации',
      icon: 'edit',
      inMenu: true,
      defaultFilter: 'filter.resourceType=RESOURCE_TYPE_PUBLICATION',
      authority: 'posts.view',
      submenu: {
        Quiz: {
          component: PublicationsPage,
          path: '/quiz',
          title: 'Опросы',
          inMenu: true,
          authority: 'quiz.view',
          defaultFilter: 'filter.resourceType=RESOURCE_TYPE_QUIZ',
        },
        Comments: {
          component: CommentsPage,
          path: '/comments',
          title: 'Комментарии',
          inMenu: true,
          defaultFilter: 'filter.status=COMMENT_STATUS_REVIEW',
          authority: 'comments.view',
        },
      },
    },
    Editor: {
      component: EditorPage,
      path: '/publications/:id',
      title: 'Редактирование публикации',
      showPostsHeader: true,
      authority: 'posts.view',
    },
    TextBroadcastEditor: {
      component: EditorPage,
      path: '/broadcasts/text/:id',
      title: 'Редактирование текстовой трансляции',
      authority: 'textTranslations.view',
    },
    Directory: {
      component: EmployeesPage,
      path: '/employees',
      title: 'Справочники',
      icon: 'unordered-list',
      inMenu: true,
      authority: [
        'employees.view',
        'authors.view',
        'departments.view',
        'regions.view',
        'tags.view',
        'badWords.view',
      ],
      submenu: {
        Employees: {
          component: EmployeesPage,
          path: '/employees',
          title: 'Сотрудники',
          inMenu: true,
          authority: 'employees.view',
        },
        Authors: {
          component: AuthorsPage,
          path: '/authors',
          title: 'Авторы',
          inMenu: true,
          authority: 'authors.view',
        },
        Departments: {
          component: DepartmentsPage,
          path: '/departments',
          title: 'Отделы',
          inMenu: true,
          authority: 'departments.view',
        },
        Tags: {
          component: TagsPage,
          path: '/tags',
          title: 'Теги',
          inMenu: true,
          authority: 'tags.view',
        },
        Regions: {
          component: RegionsPage,
          path: '/regions',
          title: 'Регионы',
          inMenu: true,
          authority: 'regions.view',
        },
        BadWords: {
          component: BadWordsPage,
          path: '/bad-words',
          title: 'Запрещенные слова',
          inMenu: true,
          authority: 'badWords.view',
        },
      },
    },
    VideoBroadcastsEdit: {
      component: VideoPage,
      path: '/broadcasts/video/edit/:id',
      title: 'Редактирование Видео',
      authority: 'videos.view',
    },
    VideoBroadcastsCreate: {
      component: VideoPage,
      path: '/broadcasts/video/new',
      title: 'Создание Видео',
      authority: 'videos.create',
    },
    Broadcasts: {
      component: PublicationsPage,
      path: '/broadcasts',
      title: 'Трансляции',
      icon: 'notification',
      inMenu: true,
      authority: ['textTranslations.view', 'videos.view'],
      submenu: {
        TextBroadcasts: {
          component: PublicationsPage,
          path: '/broadcasts/text',
          title: 'Текстовые',
          inMenu: true,
          defaultFilter: 'filter.resourceType=RESOURCE_TYPE_TEXT_STREAM',
          hideFilters: ['filter.type'],
          authority: 'textTranslations.view',
        },
        VideoBroadcasts: {
          component: VideosPage,
          path: '/broadcasts/video',
          title: 'Видео',
          inMenu: true,
          authority: 'videos.view',
        },
      },
    },
    Schedule: {
      component: SchedulePage,
      path: '/schedule',
      title: 'Расписание съёмок',
      icon: 'schedule',
      inMenu: true,
      authority: 'events.view',
    },
    Feedback: {
      component: FeedbackPage,
      path: '/feedback',
      title: 'Обратная связь',
      icon: 'message',
      inMenu: true,
      authority: 'feedbacks.view',
    },
    ChangeLog: {
      component: ChangeLogPage,
      path: '/changelog',
      title: 'Журнал изменений',
      icon: 'history',
      inMenu: true,
      authority: 'history.view',
    },
    Roles: {
      component: RolesPage,
      path: '/roles',
      title: 'Управление доступом',
      icon: 'team',
      inMenu: true,
      authority: 'roles.view',
      submenu: {
        NewRole: {
          component: RolePage,
          path: '/roles/new',
          title: 'Создание роли',
          authority: 'roles.create',
        },
        EditRole: {
          component: RolePage,
          path: '/roles/:id',
          title: 'Редактирование роли',
          authority: 'roles.update',
        },
      },
    },
    Settings: {
      component: SettingsCommonPage,
      path: '/settings/common',
      title: 'Настройки',
      icon: 'setting',
      inMenu: true,
      authority: [
        'global.view',
        'emails.view',
        'sections.view',
        'categories.view',
        'subcategories.view',
        'headings.view',
        'headersPreviews.view',
        'socials.view',
        'timers.view',
        'symbols.view',
        'infoPages.view',
        'playlists.view',
        'specProjects.view',
        'flags.view',
        'push.view',
        'movePost.view',
        'redirects.view',
        'experiments.view',
        'indexNow.update',
        'queryOrder.view',
        'notifications.view',
      ],
      submenu: {
        SettingsEmails: {
          component: SettingsEmailsPage,
          path: '/settings/emails',
          title: 'Настройка адресов электронной почты',
          authority: 'emails.view',
        },
        SettingsCategoriesSectionsPage: {
          component: SettingsCategoriesSectionsPage,
          path: '/settings/categories_and_sections',
          title: 'Настройка разделов и категорий на сайте',
          authority: [
            'sections.view',
            'categories.view',
            'subcategories.view',
            'headings.view',
            'headersPreviews.view',
          ],
        },
        SettingsSocialNetworksPage: {
          component: SettingsSocialNetworksPage,
          path: '/settings/social',
          title: 'Настройка ссылок на аккаунты в соцсетях и мессенджерах',
          authority: 'socials.view',
        },
        SettingsTimersPage: {
          component: SettingsTimersPage,
          path: '/settings/timers',
          title: 'Настройка таймеров',
          authority: 'timers.view',
        },
        SettingsSymbolsPage: {
          component: SettingsSymbolsPage,
          path: '/settings/symbols',
          title: 'Настройка количества символов блоков публикации',
          authority: 'symbols.view',
        },
        SettingsInfoPagesPage: {
          component: SettingsInfoPagesPage,
          path: '/settings/infopages',
          title: 'Настройки управлению информационными страницами',
          authority: 'infoPages.view',
        },
        SettingsPlaylistsPage: {
          component: SettingsPlaylistsPage,
          path: '/settings/playlists',
          title: 'Настройки плейлистов',
          authority: 'playlists.view',
        },
        SettingsSpecProjectsPage: {
          component: SettingsSpecProjectsPage,
          path: '/settings/specprojects',
          title: 'Специальные проекты',
          authority: 'specProjects.view',
        },
        SettingsCommonPage: {
          component: SettingsCommonPage,
          path: '/settings/common',
          title: 'Общие настройки',
          authority: 'global.view',
        },
        SettingsFlagsPage: {
          component: SettingsFlagsPage,
          path: '/settings/flags',
          title: 'Флаги',
          authority: 'flags.view',
        },
        SettingsPushPage: {
          component: SettingsPushPage,
          path: '/settings/push',
          title: 'Уведомления',
          authority: 'push.view',
        },
        SettingsMovePublicationsPage: {
          component: SettingsMovePublicationsPage,
          path: '/settings/publications_migration',
          title: 'Миграция публикаций',
          authority: 'movePost.view',
        },
        SettingsRedirectsPage: {
          component: SettingsRedirectsPage,
          path: '/settings/redirects',
          title: 'Редиректы',
          authority: 'redirects.view',
        },
        SettingsExperimentsPage: {
          component: SettingsExperimentsPage,
          path: '/settings/experiments',
          title: 'Эксперименты',
          authority: 'experiments.view',
        },
        SettingsIndexNowPage: {
          component: SettingsIndexPage,
          path: '/settings/indexNow',
          title: 'Отправка в IndexNow',
          authority: 'indexNow.update',
        },
        SettingsQueryOrderPage: {
          component: SettingsQueryOrderPage,
          path: '/settings/queryOrder',
          title: 'Порядок подбора статей',
          authority: 'queryOrder.view',
        },
        SettingsNotificationPage: {
          component: SettingsNotificationPage,
          path: '/settings/notifications',
          title: 'Уведомление сотрудников',
          authority: 'notifications.view',
        },
        SettingsLogoPage: {
          component: SettingsLogoPage,
          path: '/settings/logo',
          title: 'Лого в хедере',
          authority: 'logo.view',
        },
        SettingsViqeoPage: {
          component: SettingsViqeoPage,
          path: '/settings/viqeo',
          title: 'Настройки Viqeo',
          authority: 'global.view',
        },
      },
    },
    NotFound: {
      component: NotFound,
    },
  },
};

export const leftMenu = [
  'Index',
  'Directory',
  'Employees',
  'Authors',
  'Departments',
  'Tags',
  'Broadcasts',
  'TextBroadcasts',
  'VideoBroadcasts',
  'Schedule',
  'Feedback',
  'ChangeLog',
  'Settings',
];

export default routes;
