import { Checkbox, CheckboxOptionType, Col, Row } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import styles from './styles.scss';

const { Group } = Checkbox;

const getFlagAction = flag => {
  switch (flag) {
    // case 'darkSide':
    case 'adultContent':
    case 'criminalContent':
      // case 'isWide':
      return 'flagsBlackAdultCriminalWideCover';

    case 'commentsAllowed':
    case 'pushAllowed':
    case 'advDisabled':
      // case 'blockedByRKN':
      return 'flagsAdCommentPushRKN';

    default:
      return flag;
  }
};

export const FlagsCheckboxGroup = observer(() => {
  const {
    rssTypes,
    resourceType,
    rssJson: rss = [],
    flagsArray,
    setFlags,
    setRss,
  } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const flagTitle = {
    commentsAllowed: 'Комментарии в посте',

    advDisabled: 'Отключить рекламу в публикации',
    pushAllowed: 'Пуш уведомления',
    // darkSide: 'Тёмная сторона',
    adultContent: 'Adult (18+)',
    criminalContent: 'Criminal (18+)',
    // blockedByRKN: 'Блокировка РКН',
    // isWide: 'Широкий формат',
    noindex: 'Noindex',
  };

  const optionsFlags: CheckboxOptionType[] = Object.entries(flagTitle).map(([key, value]) => ({
    value: key,
    label: value,
    disabled: !checkPermissions(`posts.${getFlagAction(key)}`),
  }));

  const optionsRSS: LabeledValue[] = (rssTypes as IDictionary[])
    .filter(({ id, isActive }) => isActive || rss.includes(id))
    .map(({ id, ru }) => ({
      value: id,
      label: ru,
      disabled: !checkPermissions('posts.rss'),
    }))
    .sort((a, b) => rss.indexOf(b.label) - rss.indexOf(a.label));

  return (
    <Row gutter={[32, 16]}>
      <Col xs={24} md={12}>
        <ItemWithLabel label="Настройки">
          <Group
            className={styles.checkboxGroup}
            options={optionsFlags}
            onChange={setFlags}
            value={flagsArray}
          />
        </ItemWithLabel>
      </Col>
      <Col xs={24} md={12}>
        <ItemWithLabel label="RSS">
          <Group
            className={styles.checkboxGroup}
            options={optionsRSS}
            onChange={setRss}
            value={rss}
          />
        </ItemWithLabel>
      </Col>
    </Row>
  );
});
